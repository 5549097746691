class versionInfo {
  constructor() {
    this.version = "examine-adjust-queue-large-knowns";
    this.revision = "d84dfa68cda08397deb3c21f0233471e1f7376b5";
    this.commitCount = ".3";
    this.buildDate = "Wed Mar  5 17:15:06 UTC 2025";
    this.isProduction = !this.version.startsWith("{{"); // The script for building the production container will substitute the above values

    if (!this.isProduction) {
      // For dev environment determine version from git
      try {
        this.version = require("child_process")
          .execSync("git rev-parse --abbrev-ref HEAD")
          .toString()
          .trim();
      } catch (err) {
        this.version = "DEV";
      }

      try {
        this.revision = require("child_process")
          .execSync("git rev-parse HEAD")
          .toString()
          .trim();
      } catch (err) {
        this.revision = "DEV";
      }

      try {
        this.commitCount = require("child_process")
          .execSync("git rev-list --count origin/master..")
          .toString()
          .trim();
      } catch (err) {
        this.commitCount = "DEV";
      }

      this.buildDate = new Date().toUTCString();
    }
  }
}

module.exports = versionInfo;
